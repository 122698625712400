<template>
	<Application
		:state="state"
		:main-title="mainTitle" />
</template>

<script setup lang="ts">
	import type { IComplainInfoProps } from "~/components/global/application-contesting-info/interfaces/IComplainInfoProps";
	import { ComplainInfo } from "~/components/global/application-contesting-info/models/ComplainInfo";

	import { ComplainInfoApiService } from "~/components/global/application-contesting-info/models/ComplainInfoApiService";
	import { ApplicationState } from "~/components/global/application/models/ApplicationState";

	const props = defineProps<IComplainInfoProps>();

	const info = new ComplainInfo(props.guid, props.type);
	const infoState = new ApplicationState(
		ref({
			attributes: {
				answer: "",
				answer_date: "",
				date: "",
				parent: "",
				reason: "",
			},
			type: "",
			uuid: "",
		}),
	);

	const infoService = new ComplainInfoApiService(info, infoState);

	await infoService.checkReceiveComplaint();

	const { state } = infoState;

	const mainTitle = ref("Оспаривание постановления");
</script>
